import React from 'react';

export default function UnderConstruction() {

    return (
        <div>
            <h1>Under Construction</h1>
            Please check again later.
        </div>
    )
}