import React from 'react';

export default function Projects() {
    return (
    <div>
        <h1>Projects</h1>
        <div>
            <h2><a href="https://discordapp.com/oauth2/authorize?client_id=599401316107943946&permissions=8&scope=bot" target="_blank" rel="noopener noreferrer">Delarious Bot</a></h2>
        </div>
    </div>)
}